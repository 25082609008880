import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiFetchDetailFromServer, apiFetchQuizFromServer, apiSendAnswerToServer } from "./api";

// Slice
export const quizSlice = createSlice({
    name: "quiz",
    initialState: {
        soal: [],
        detail: {
            endTime: 0,
            step: 0,
        },
        status: "welcome",
    },
    reducers: {
        setAnswer: (state, action) => {
            state.soal[state.detail.step].answer.selection = action.payload;
        },
        unsetAnswer: (state) => {
            state.soal[state.detail.step].answer.selection = null;
        },
        next: (state, action) => {
            if (!action.payload) state.soal[state.detail.step].answer.selection = null;
            if (state.detail.step < state.soal.length - 1) state.detail.step++;
        },
        setStep: (state, action) => {
            state.detail.step = action.payload;
        },
        previous: (state) => {
            if (state.detail.step > 0) state.detail.step--;
        },
        setError: (state) => {
            state.status = "error";
        },
        batal: (state) => {
            state.soal = [];
            state.detail = {
                endTime: 0,
                step: 0,
            };
            state.status = "welcome";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchQuizFromServer.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchQuizFromServer.fulfilled, (state, action) => {
                state.status = "ujian";
                state.soal = action.payload.soal;
                state.detail.endTime = new Date(
                    action.payload.detail.endTime
                ).getTime() / 1000;
                state.detail.id_ujian = action.payload.detail.id_ujian
            })
            .addCase(fetchDetailFromServer.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchDetailFromServer.fulfilled, (state, action) => {
                state.status = "welcome";
                state.detail = {
                    ...state.detail,
                    ...action.payload,
                };
            })
            .addCase(sendAnswerToServer.pending, (state) => {
                state.status = "loading";
            })
            .addCase(sendAnswerToServer.fulfilled, (state, action) => {
                state.status = "completed";
                // save result to state
                state.detail.score = action.payload.bobot;
            });
    },
});

// Async Function
export const fetchQuizFromServer = createAsyncThunk(
    "quiz/apiFetchQuizFromServer",
    async (x) => {
        const response = await apiFetchQuizFromServer(x);
        console.log(response);
        return response.data;
    }
);

export const fetchDetailFromServer = createAsyncThunk(
    "quiz/apiFetchDetailFromServer",
    async (x) => {
        const response = await apiFetchDetailFromServer(x);
        return {
            ...response.data,
            ...x,
        };
    }
);

export const sendAnswerToServer = createAsyncThunk(
    "quiz/apiSendAnswerToServer",
    async (x) => {
        const response = await apiSendAnswerToServer(x);
        // pass data from api to reducer
        return {
            ...response.data,
            ...x,
        };
    }
);

// Action
export const {
    setAnswer,
    unsetAnswer,
    next,
    setStep,
    previous,
    completed,
    setError,
    batal,
} = quizSlice.actions;
// Selector
export const selector = (state) => state.quiz;
// Reducer
export default quizSlice.reducer;
