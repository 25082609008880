import Ujian from "./screens/Ujian";
import Welcome from "./screens/Welcome";
import Completed from "./screens/Completed";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import * as quiz from "./store/quiz";
import _ from "lodash";

import queryString from "query-string";

function App() {
    const params = queryString.parse(window.location.search);

    const { status, detail } = useSelector(quiz.selector);
    const dispatch = useDispatch();

    useEffect(() => {
        if (_.isEmpty(detail.jumlah_soal)) {
            dispatch(quiz.setError());
        } else {
            window.history.pushState("ujian", "Ujian", "/");
        }
    }, [dispatch, detail]);

    useEffect(() => {
        if (_.isEmpty(detail.nama)) {
            if (
                _.isEmpty(params.id_user) ||
                _.isEmpty(params.id_paket) ||
                _.isEmpty(params.id_fasilitas)
            ) {
                dispatch(quiz.setError());
            } else {
                // ?id_user=15&id_paket=2&id_fasilitas=18&id_tryout=0
                dispatch(
                    quiz.fetchDetailFromServer({
                        id_user: params.id_user,
                        id_paket: params.id_paket,
                        id_fasilitas: params.id_fasilitas,
                        id_tryout: params.id_tryout,
                    })
                );
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, params]);
    if (status === "ujian") {
        return <Ujian />;
    } else if (status === "welcome") {
        return <Welcome />;
    } else if (status === "completed") {
        return <Completed />;
    } else if (status === "error") {
        return (
            <div className="flex h-screen justify-center items-center font-semibold">
                Error
            </div>
        );
    } else {
        return (
            <div className="flex h-screen justify-center items-center font-semibold">
                Loading..
            </div>
        );
    }
}

export default App;
