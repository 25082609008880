import { useEffect } from "react";
import { useSelector } from "react-redux";
import * as quiz from "../store/quiz";

export default function Completed() {
    const { detail } = useSelector(quiz.selector);


    useEffect(() => {
        if (detail.id_paket) {
            // Redirect to the URL with id_paket
            window.location.href = `//test.idolacasn.com/hasil/try-out/${detail.id_paket}`;
        }
    }, [detail.id_paket]);
    
    return (
        <div className="bg-white sm:bg-gray-100 mx-auto flex justify-center items-center h-screen">
            <div className="text-center p-10 sm:bg-white w-96 rounded-lg sm:shadow-lg">
                <h1 className="text-2xl font-semibold">Menghitung Jawaban..</h1>
            </div>
        </div>
    );

    // return (
    //     <div className="bg-white sm:bg-gray-100 mx-auto flex justify-center items-center h-screen">
    //         <div className="text-center py-10 sm:bg-white w-96 rounded-lg sm:shadow-lg">
    //             <h1 className="text-4xl font-semibold">Your Score</h1>
    //             <h1 className="text-[#9e6925] font-semibold text-9xl">
    //                 {detail.score}
    //             </h1>
    //             <div className="mt-5 flex justify-center">
    //                 <a
                    
    //                     href={`//test.idolacasn.com/hasil/try-out`}
    //                     // href={`//test.idolacasn.com/ujian/hasil-ujian/${detail.id_ujian}/${detail.id_fasilitas}/${detail.id_paket}`}
    //                     className="bg-[#9e6925] hover:opacity-80 flex items-center gap-2 w-max text-white font-semibold p-2 px-5 rounded-lg"
    //                 >
    //                     <svg
    //                         xmlns="http://www.w3.org/2000/svg"
    //                         width="24"
    //                         height="24"
    //                         viewBox="0 0 24 24"
    //                         className="fill-white"
    //                     >
    //                         <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path>
    //                     </svg>
    //                     Lihat Analisis
    //                 </a>
    //             </div>
    //         </div>
    //     </div>
    // );
}
