import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import * as quiz from "../store/quiz";

const calculateDuration = (eventTime) =>
    moment.duration(
        Math.max(eventTime - Math.floor(Date.now() / 1000), 0),
        "seconds"
    );

export default function Countdown({ eventTime, interval }) {
    const dispatch = useDispatch();

    const [duration, setDuration] = useState(calculateDuration(eventTime));
    const timerRef = useRef(0);
    const timerCallback = useCallback(() => {
        let a = calculateDuration(eventTime)
        setDuration(a);
        if (a.asMilliseconds() <= 0) {
            clearInterval(timerRef.current);
            dispatch(quiz.completed())
        }
    }, [eventTime]);

    useEffect(() => {
        timerRef.current = setInterval(timerCallback, interval);

        return () => {
            clearInterval(timerRef.current);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventTime]);

    return (
        <>
            {duration.hours().toString().padStart(2, 0)}:
            {duration.minutes().toString().padStart(2, 0)}:
            {duration.seconds().toString().padStart(2, 0)}
        </>
    );
}
