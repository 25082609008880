import { useDispatch, useSelector } from "react-redux";
import * as quiz from "../store/quiz";

import { FaArrowRight, FaCheckCircle, FaSave } from "react-icons/fa";

export default function SoalNav() {
    const { soal, detail } = useSelector(quiz.selector);
    const dispatch = useDispatch();

    const handleSelesai = () => {
        console.log("selesai");
        let jawaban = soal.map((item) => {
            return {
                id_soal: item.id,
                jawaban: item.answer.selection || null,
            };
        });
        
        dispatch(
            quiz.sendAnswerToServer({
                id_ujian: detail.id_ujian,
                jawaban
            })
        );
    };

    return (
        <div className="flex justify-center gap-2 items-center">
            {detail.step >= soal.length - 1 ? (
                <button
                    className="flex disabled:bg-gray-400 justify-center items-center gap-2 rounded-md border border-transparent bg-green-500 py-2 px-4 text-sm font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    onClick={handleSelesai}
                >
                    Selesai
                    <FaCheckCircle />
                </button>
            ) : (
                <>
                    <button
                        className="flex disabled:bg-gray-400 justify-center items-center gap-2 rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        onClick={() => dispatch(quiz.next(true))}
                    >
                        <FaSave />
                        Simpan Dan Lanjutkan
                    </button>
                    <button
                        className="flex disabled:bg-gray-400 justify-center items-center gap-2 rounded-md border border-transparent bg-gray-400 py-2 px-4 text-sm font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                        onClick={() => dispatch(quiz.next())}
                    >
                        Lewati Soal
                        <FaArrowRight />
                    </button>
                </>
            )}
        </div>
    );
}
