export function apiFetchQuizFromServer(p) {
    return new Promise((resolve) => {
        (async () => {
            let data = await fetch(
                `https://api1.idolacasn.com/detailUjian.php?id_user=${p.id_user}&id_paket=${p.id_paket}&id_fasilitas=${p.id_fasilitas}&id_tryout=${p.id_tryout}`
            );
            data = await data.json();
            console.log('apiFetchQuizFromServer', data);
            resolve({ data });
        })();
    });
}

export function apiFetchDetailFromServer(p) {
    return new Promise((resolve) => {
        (async () => {
            let data = await fetch(
                `https://api1.idolacasn.com/mulaiUjian.php?id_user=${p.id_user}&id_paket=${p.id_paket}&id_fasilitas=${p.id_fasilitas}&id_tryout=${p.id_tryout}`
            );
            data = await data.json();
            console.log('apiFetchDetailFromServer',data);
            resolve({ data });
        })();
    });
}

export function apiSendAnswerToServer(p) {
    return new Promise((resolve) => {
        (async () => {
            let data = await fetch(
                `https://api1.idolacasn.com/simpanUjian.php?id_ujian=${p.id_ujian}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(p.jawaban),
                }
            );
            data = await data.json();
            console.log('apiSendAnswerToServer',data);
            resolve({ data });
        })();
    });
}
