import React from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import * as quiz from "../store/quiz";

export default function SoalList() {
    const { soal, detail } = useSelector(quiz.selector);
    const dispatch = useDispatch();

    return (
        <>
            <div className="flex justify-between">
                <div>
                    <div>
                        Total Soal
                    </div>
                    <div className="font-semibold text-xl">
                        {soal.length}
                    </div>
                </div>
                <div>
                    <div>
                        Dijawab
                    </div>
                    <div className="font-semibold text-xl">
                        {soal.filter((e) => e.answer.selection).length}
                    </div>
                </div>
                <div>
                    <div>
                        Belum Dijawab
                    </div>
                    <div className="font-semibold text-xl">
                        {soal.length - soal.filter((e) => e.answer.selection).length}
                    </div>
                </div>
            </div>
            <div className="max-h-40 xl:max-h-52 overflow-auto">
                <div className="flex gap-2 mb-5 flex-wrap justify-center">
                    {soal.map((e, i) => {
                        return (
                            <button
                                key={i}
                                className={`bg-gray-100 border w-10 h-10 font-semibold rounded ${
                                    i === detail.step
                                        ? "bg-blue-500 text-white"
                                        : typeof e.answer.selection ===
                                          "undefined"
                                        ? "bg-red-500 text-white"
                                        : e.answer.selection === null
                                        ? "bg-gray-100 text-gray-500"
                                        : "bg-green-500 text-white"
                                }`}
                                disabled={detail.step === i}
                                onClick={() => dispatch(quiz.setStep(i))}
                            >
                                {i + 1}
                            </button>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
